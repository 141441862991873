import React from "react";
import Routers from "./routes";

const App = () => {
  return (
    <div>
      <Routers />
    </div>
  );
}
export default App;